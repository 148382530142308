@use 'sass:math';
@use 'dawuti.config' as dawuti;

/**
 * 
 * VARIABLES
 * 
**/

:root {
  // GENERAL
  --s: 5px;
  --gutter: 15px;

  --safe-area: calc(var(--s) * 3);
  @include dawuti.mq-sm {
    --safe-area: calc(var(--gutter) * 2);
  }
  @include dawuti.mq-lg {
    --safe-area: calc(var(--gutter) * 4);
  }

  --font-primary: 'Roboto', sans-serif;
  --font-size-base: 15px;
  --line-height-base: 18px;
  @include dawuti.mq-md {
    --font-size-base: 16px;
    --line-height-base: 19.2px;
  }
  @include dawuti.mq-md {
    --font-size-base: 16px;
    --line-height-base: 19.2px;
  }

  --font-size-bigger: 18px;
  --line-height-bigger: 21.6px;
  @include dawuti.mq-md {
    --font-size-bigger: 20px;
    --line-height-bigger: 24px;
  }

  --font-size-small: 12px;
  --line-height-small: 14.4px;
  @include dawuti.mq-md {
    --font-size-small: 14px;
    --line-height-small: 16.8px;
  }

  // COLORS
  --color-primary: #181b18;
  --color-primary-rgb: 24, 27, 24;
  --color-primary-contrast: #fff;
  --color-primary-contrast-rgb: 255, 255, 255;
  --color-secondary: #da291c;
  --color-secondary-rgb: 218, 41, 28;
  --color-secondary-contrast: #fff;
  --color-secondary-contrast-rgb: 255, 255, 255;
  --color-additional: #4494A9;

  --color-light: #fff;
  --color-text: var(--color-primary);
  --color-text-rgb: var(--color-primary-rgb);
  --color-text-grey: #787878;

  --color-grey: rgba(120, 120, 120, 0.1);
  --color-grey-light: rgba(120, 120, 120, 0.05);
  --color-grey-dark: rgba(120, 120, 120, 0.2);
  --color-grey-darker: rgba(120, 120, 120, 1);

  --color-border: rgba(120, 120, 120, 0.4);

  --btn-primary-bg-color: var(--color-primary);
  --btn-primary-color: var(--color-primary-contrast);
  --btn-primary-hover-bg-color: var(--color-secondary);

  --btn-secondary-bg-color: var(--color-secondary-contrast);
  --btn-secondary-color: var(--color-secondary);
  --btn-secondary-hover-bg-color: var(--color-primary);
  --btn-secondary-hover-color: var(--color-primary-contrast);
  --btn-secondary-disabled: rgba(218, 41, 28, 0.2);

  --btn-card-primary-bg-color: var(--color-secondary);
  --btn-card-primary-color: var(--color-secondary-contrast);
  --btn-card-primary-hover-bg-color: var(--color-secondary);

  // HEADINGS
  --h1-font-size: 48px;
  --h1-line-height: 52.8px;
  --h1-font-weight: 700;
  @include dawuti.mq-md {
    --h1-font-size: 56px;
    --h1-line-height: 61.6px;
  }

  --h2-font-size: 40px;
  --h2-line-height: 44px;
  --h2-font-weight: 700;
  @include dawuti.mq-md {
    --h2-font-size: 48px;
    --h2-line-height: 52.8px;
  }

  --h2-strong-font-size: 40px;
  --h2-strong-line-height: 44px;
  --h2-strong-font-weight: 700;
  @include dawuti.mq-md {
    --h2-strong-font-size: 54px;
    --h2-strong-line-height: 59.4px;
  }

  --h3-font-size: 26px;
  --h3-line-height: 28.6px;
  --h3-font-weight: 300;
  @include dawuti.mq-md {
    --h3-font-size: 30px;
    --h3-line-height: 33px;
  }

  --h4-font-size: 22px;
  --h4-line-height: 24.2px;
  --h4-font-weight: 700;
  @include dawuti.mq-md {
    --h4-font-size: 26px;
    --h4-line-height: 28.6px;
  }

  --home-h1-font-size: 26px;
  --home-h1-line-height: 40px;
  @include dawuti.mq-md {
    --home-h1-font-size: 60px;
    --home-h1-line-height: 60px;
  }
  @include dawuti.mq-lg {
    --home-h1-font-size: 80px;
    --home-h1-line-height: 80px;
  }

  --home-h1-strong-font-size: 60px;
  --home-h1-strong-line-height: 40px;
  @include dawuti.mq-md {
    --home-h1-strong-font-size: 90px;
    --home-h1-strong-line-height: 90px;
  }
  @include dawuti.mq-lg {
    --home-h1-strong-font-size: 120px;
    --home-h1-strong-line-height: 120px;
  }

  --btn-font-size: 16px;
  --btn-line-height: 36px;

  --menu-font-size: 22px;
  --menu-line-height: 26.4px;
  @include dawuti.mq-md {
    --menu-font-size: 14px;
    --menu-line-height: 16.8px;
  }

  --copyright-font-size: 14px;
  --copyright-line-height: 16.8px;

  --card-title-font-size: 20px;
  --card-title-line-height: 22px;

  // Toolbar
  --toolbar-height: 78px;
  @include dawuti.mq-md {
    --toolbar-height: 114px;
  }

  // Header
  --header-height: 71px;
  @include dawuti.mq-md {
    --header-height: 90px;
  }
}
