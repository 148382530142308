@import "@material-design-icons/font/outlined";
@import "@material-design-icons/font/filled";

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
