@use 'dawuti.config' as dawuti;
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$primary-palette: (
  50: #ffebed,
  100: #ffccce,
  200: #f69993,
  300: #ed7169,
  400: #f65041,
  500: #fa3f20,
  600: #ec3421,
  700: #da291c,
  800: #cd2014,
  900: #bf0d02,
  A100: #ffffff,
  A200: #fff3f2,
  A400: #ffc6bf,
  A700: #ffaea6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$accent-palette: (
  50: #e5e5e5,
  100: #bebebe,
  200: #929292,
  300: #666666,
  400: #464646,
  500: #252525,
  600: #212121,
  700: #1b1b1b,
  800: #161616,
  900: #0d0d0d,
  A100: #e5e5e5,
  A200: #929292,
  A400: #464646,
  A700: #1b1b1b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$hmi-elite-web-primary: mat.define-palette($primary-palette);
$hmi-elite-web-accent: mat.define-palette($accent-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$hmi-elite-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$hmi-elite-web-theme: mat.define-light-theme(
  (
    color: (
      primary: $hmi-elite-web-primary,
      accent: $hmi-elite-web-accent,
      warn: $hmi-elite-web-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($hmi-elite-web-theme);

body,
html {
  height: 100%;
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}
@import 'swiper/swiper-bundle.css';
@import 'swiper/swiper-bundle.min.css';
@import 'swiper/modules/navigation/navigation.scss';
@import 'swiper/modules/pagination/pagination.scss';

@import './variables';
@import './fonts';

.wrapper-fluid {
  padding-left: var(--safe-area);
  padding-right: var(--safe-area);
}

.wrapper-centered {
  padding-left: var(--safe-area);
  padding-right: var(--safe-area);
  max-width: 1440px;
  margin: 0 auto;
}

h1,
.h1 {
  font-size: var(--h1-font-size);
  line-height: var(--h1-line-height);
  font-weight: var(--h1-font-weight);
}

h2,
.h2 {
  font-size: var(--h2-font-size);
  line-height: var(--h2-line-height);
  font-weight: var(--h2-font-weight);
  margin: 0 0 calc(var(--s) * 5);
}

h3,
.h3 {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: var(--h3-font-weight);
  margin: 0 0 calc(var(--s) * 2);
}

h4,
.h4 {
  font-size: var(--h4-font-size);
  line-height: var(--h4-line-height);
  font-weight: var(--h4-font-weight);
}

p {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  margin: 0 0 calc(var(--s) * 4);
}

ul {
  margin: 0 0 0 3em;
  padding: 0;
  &.links {
    margin-left: 0;
  }
}

// Buttons
.btn {
  display: inline-block;
  text-transform: uppercase;
  font-size: var(--btn-font-size);
  line-height: var(--btn-line-height);
  padding: 12px calc(var(--s) * 8);
  font-weight: 500;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.35s ease-in-out;
  cursor: pointer;
  &.btn-primary {
    background-color: var(--btn-primary-bg-color);
    color: var(--btn-primary-color);
    &:hover,
    &:focus,
    &:active {
      background-color: var(--btn-primary-hover-bg-color);
    }
  }
  &.btn-secondary {
    background-color: var(--btn-secondary-bg-color);
    color: var(--btn-secondary-color);
    &:hover,
    &:focus,
    &:active {
      background-color: var(--btn-secondary-hover-bg-color);
      color: var(--btn-secondary-hover-color);
    }
  }
  &.btn-card-primary {
    background-color: var(--btn-card-primary-bg-color);
    color: var(--btn-card-primary-color);
    box-shadow: none;
    font-family: var(--font-primary);
    height: 48px;
    border: none;
    padding-top: 6px;
    padding-bottom: 6px;
    &:hover,
    &:focus,
    &:active {
      background-color: var(--btn-secondary-hover-bg-color);
      color: var(--btn-secondary-hover-color);
    }
  }
}

.btn-video {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  .icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    border-radius: 72px;
    border: 1.5px solid var(--color-light);
    background-color: rgba(255, 255, 255, 0.15);
    transition: all 0.35s ease-in-out;
    fmp-icon-play {
      width: 11px;
      height: 13px;
      display: flex;
      margin-left: var(--s);
    }
  }
  span:not(.icon) {
    color: var(--color-light);
    text-transform: none;
    font-size: var(--btn-font-size);
    font-family: var(--font-primary);
    font-weight: 500;
    margin-left: calc(var(--s) * 4);
  }
  &:hover {
    .icon {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }
}

.btn-select {
  padding: 11px 12px;
  background-color: transparent;
  border: 1px solid rgba(120, 120, 120, 0.4);
  border-radius: 8px;
  font-family: var(--font-primary);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  &.visible {
    svg {
      transform: rotate(180deg);
    }
  }
  svg {
    margin-left: calc(var(--s) * 2);
  }
}

.clean-button {
  margin-left: calc(var(--s) * 3);
  background-color: transparent;
  border: none;
  color: rgba(120, 120, 120, 0.8);
  font-size: var(--font-size-small);
  font-family: var(--font-primary);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.35s ease-in-out;
  &:hover {
    color: var(--color-primary);
  }
}

// Chip
.mat-mdc-chip-grid,
.mat-mdc-chip,
.mat-mdc-chip-action {
  cursor: default !important;
}
.mat-mdc-chip-row{
  border-radius: 3px !important;
}

mat-icon{
  vertical-align: middle;
  img{
    vertical-align: middle;
  }
}

.chip-name {
  font-weight: 400;
  font-size: 14px;
  // position: relative;
  top: -5px;
  filter: brightness(50%);
}

.chip-icon{
  width: 20px;
  position: relative;
  vertical-align: middle;
  top: 8px;
  font-size: 14px;
  filter: brightness(85%);
}

// Dialog
.cdk-overlay-container {
  .cdk-overlay-backdrop {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane.video-dialog {
      width: 100%;
      max-width: 100% !important;
      @media screen and (min-width: 768px) {
        max-width: 660px !important;
        max-width: 80vw !important;
      }
      @media screen and (min-width: 1200px) {
        max-width: 760px !important;
      }
      .mat-mdc-dialog-surface {
        padding: 0;
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
}
.mat-mdc-dialog-container {
  padding: 0 !important;
  overflow: hidden !important;
}
.mat-mdc-menu-panel.filter-wrapper {
  width: 220px;
  border: 1px solid #787878;
  border-radius: 8px;
  background-color: var(--color-light);
  padding: 0 10px;
  position: absolute;
  right: 0;
  z-index: 1;
  overflow: hidden;
  display: flex;
  cursor: pointer;
  .mat-mdc-menu-content {
    width: 100%;
  }
}
.mat-mdc-paginator-container {
  padding: 0 !important;
}

// Expansion Panel

.mat-expansion-indicator {
  display: none !important;
}

// Stepper

.mat-horizontal-stepper-header-container {
  margin-bottom: 1.5em !important;
}

.mat-horizontal-stepper-header {
  @media screen and (max-width: 1185px) {
    padding: 0 !important;
  }
}

.menuOptions {
  &.mat-mdc-menu-panel.mat-mdc-menu-panel {
    padding: 8px;
    max-width: none;
  }
  mat-button-toggle-group.mat-button-toggle-group:last-child {
    margin-left: 0.7em;
  }
}

.text-marked{
    table{
      border-collapse: collapse;
      border-spacing: 0px;
      display: block;
      overflow: auto;
    
      thead{
        background: var(--color-grey);
    
        tr{
          border: 1px solid var(--color-grey);
    
          th{
            padding: 16px;
            border-width: 0px 1px;
            border-right-style: solid;
            border-left-style: solid;
            border-right-color: var(--color-grey);
            border-left-color: var(--color-grey);
            border-image: initial;
            border-bottom-style: initial;
            border-bottom-color: initial;
            border-top-style: initial;
            border-top-color: initial;
          }
        }
      }
    
      tbody{
        tr{
          border: 1px solid var(--color-grey);
    
          td{
            padding: 16px;
            border-width: 0px 1px;
            border-right-style: solid;
            border-left-style: solid;
            border-right-color: var(--color-grey);
            border-left-color: var(--color-grey);
            border-image: initial;
            border-bottom-style: initial;
            border-bottom-color: initial;
            border-top-style: initial;
            border-top-color: initial;
          }
        }
      }
    }
}

mark {
  background-color: rgb(255, 219, 222) !important;
}